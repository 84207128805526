<template lang="pug">
#purchase-remove-bg-credits
  CanvasModal(
    :is-show="showRemoveBgPurchaseOverlay"
    :class="{ 'is-loading': fetchingPackages, 'modal--payment-page': !!selectedPackage}"
    :max-width="1000"
    @closeOverlay="closeOverlay"
  )
    template(v-if="fetchingPackages")
      BasePreloader

    template(v-else)
      template(v-if="!selectedPackage")

        template(v-if="!hasAvailablePackage")
          p Sorry no available package at the moment.

        template(v-else)
          h2.credit-title Buy more credits
          div.package-container(
            v-for="(item, index) in removeBgPackages"
            :key="`remove-bg-packages-${item.id}`"
          )
            div.package-number(@click="setSelectedCredit(item.credits)")
              input.radio-checker(
                type="radio"
                :id="item.id"
                name="credit"
                :checked="selectedCredit === item.credits"
              )
              label.package-title(:for="item.id") {{ item.credits }} Credits
            BaseButton.package-button(
              :is-success="selectedCredit === item.credits"
              :plain-hover="true"
              @click="selectPackage(item)"
            ) ${{ item.cost }}

      template(v-else)
        .credit-braintree-title
          BaseButton(
            :isText="true"
            iconBefore="icon-left-arrow-long"
            @click="selectedPackage = ''"
          )
          h2.credit-title Confirm Payment

        .braintree-form(
          ref="braintreeForm"
        )
          BasePreloader(v-if="isProcessing")
          v-braintree(
            v-else
            :authorization="braintreeClientToken"
            :paypal="payPal"
            :btnText="btnText"
            :btnClass="btnClass"
            :vaultManager="isBraintreeCustomer"
            @load="onLoad"
            @loadFail="onLoadFail"
            @success="onSuccess"
            @error="onError"
          )

  BaseDialog.purchase-success(
    :is-show="showPurchaseSuccess"
    no-button=true
    @closeOverlay="showPurchaseSuccess = false"
  )
    .purchase-success--content
      img(
        src="@/assets/images/icons/remove-bg-payment-tick.svg"
        alt="Your purchase is successful"
      )
      h5.purchase-success-title Payment Success
      p.purchase-success-subtitle Congrats! Your purchased credits have been added to your account.

      BaseButton.close-window__button(
        is-success=true
        :plain-hover="true"
        @click="showPurchaseSuccess = false"
      ) Back to Canvas
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import helperMixin from '@/components/mixins/helper-mixins';

import Api from '@/services/api/Api';

export default {
  name: 'RemoveBgPurchaseOverlay',
  mixins: [helperMixin],
  data() {
    return {
      instance: '',
      selectedPackage: '',
      selectedCredit: '',
      showPaymentForm: false,
      isProcessing: false,
      btnClass: 'btn btn--hidden btn-purchase-confirm',
      showPurchaseButton: false,
      defaultErrorMessage: 'Sorry something went wrong, try again later',
      fetchingPackages: true,
      braintreeClientToken: '',
      isBraintreeCustomer: false,
      showPurchaseSuccess: false,
      purchasedCredits: 0,
    };
  },
  // props: {},
  computed: {
    ...mapState(['removeBgPackages', 'showRemoveBgPurchaseOverlay']),
    btnText() {
      return `$${this.selectedPackage.cost} – Buy ${this.selectedPackage.credits} credits`;
    },
    payPal() {
      return {
        flow: 'vault',
      };
    },
    hasAvailablePackage() {
      // console.log('hasAvailablePackage', this.removeBgPackages);
      return this.removeBgPackages.length > 0;
    },
    creditsCredited() {
      return `${this.purchasedCredits} credits have been added to your account. You may now close this window.`;
    },
  },
  mounted() {
    this.selectedCredit = 100;
  },
  methods: {
    ...mapMutations(['setRemoveBgPackages', 'setShowRemoveBgPurchaseOverlay']),
    ...mapMutations('userData', ['setRemoveBgCredits']),
    setSelectedCredit(item) {
      this.selectedCredit = item;
    },
    selectPackage(item) {
      this.selectedPackage = item;
    },
    closeOverlay() {
      this.selectedPackage = '';
      this.setShowRemoveBgPurchaseOverlay(false);
    },
    setupBraintreeDropInCredentials() {
      Api.getBraintreeClientToken()
        .then((response) => {
          if (response.data.success) {
            this.isBraintreeCustomer = true;
            this.braintreeClientToken = response.data.results;
          } else {
            this.isBraintreeCustomer = false;
            this.braintreeClientToken = process.env.VUE_APP_BRAINTREE_TOKEN;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data.message || this.defaultErrorMessage;
          this.alertError('', errorMessage, 5000, 'TopCenterNotif');
        });
    },
    onLoad(instance) {
      this.instance = instance;
      this.$refs.braintreeForm
        .querySelector('.btn-purchase-confirm')
        .classList.remove('btn--hidden');
      this.$refs.braintreeForm.classList.add('is-loaded');
    },
    onLoadFail() {
      // console.log('load fail');
      this.closeOverlay();
      this.alertError('', this.defaultErrorMessage, 5000, 'TopCenterNotif');
    },
    onSuccess(payload) {
      // const { nonce } = payload;
      // console.log('braintree payload', payload);

      this.isProcessing = true;

      const params = {
        nonce: payload.nonce,
        package_id: this.selectedPackage.id,
      };

      Api.removeBgPurchaseCredit(params)
        .then((response) => {
          console.log(response.data.success);
          if (response.data.success) {
            if (response.data.new_customer) {
              this.setupBraintreeDropInCredentials();
            }
            this.purchasedCredits = this.selectedPackage.credits;
            this.showPurchaseSuccess = true;
            this.selectedPackage = '';
            this.setRemoveBgCredits(response.data.new_credits);
            this.closeOverlay();
          } else {
            const errorMessage = response.data.message || this.defaultErrorMessage;
            this.alertError('', errorMessage, 5000, 'TopCenterNotif');
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data.message || this.defaultErrorMessage;
          this.alertError('', errorMessage, 5000, 'TopCenterNotif');
          this.closeOverlay();
          // console.log(error);
        })
        .then(() => {
          // this.isShow = false;
          this.isProcessing = false;
        });
    },
    onError() {
      console.log('error');
      this.closeOverlay();
      this.alertError('', this.defaultErrorMessage, 5000, 'TopCenterNotif');
    },
    fetchRemoveBgPackages() {
      this.fetchingPackages = true;
      Api.getRemoveBgPackages()
        .then((response) => {
          if (response.data.success) {
            const packages = response.data.results;
            // console.log('packages', packages);
            this.setRemoveBgPackages(packages);
            // console.log(this.removeBgPackages);
          }
        })
        .catch((error) => {
          console.log(error);
          this.fetchRemoveBgPackages();
        })
        .then(() => {
          this.fetchingPackages = false;
        });
    },
  },
  watch: {
    showRemoveBgPurchaseOverlay(val) {
      if (val) {
        this.setupBraintreeDropInCredentials();
        this.fetchRemoveBgPackages();
      }
    },
  },
};
</script>

<style lang="scss">
#purchase-remove-bg-credits {
  .canvas-modal {
    &.is-loading {
      .canvas-modal__content {
        .content {
          display: flex;
          align-items: center;
        }
      }
    }

    &.modal--payment-page {
      .canvas-modal__content {
        width: 600px;
      }
    }
  }

  .canvas-modal__content {
    width: 420px;
    background: var(--white-darkgrey900);

    .content {
      max-height: 650px;
      min-height: 350px;
      padding: 50px 40px;
    }
  }

  .canvas-modal__closebtn {
    color: var(--darkgrey-white);
  }

  .credit-title {
    text-align: left;
    font-weight: 600;
    font-size: 1.125rem;
    margin: 0;
    color: var(--darkgrey-white);
  }

  .package-container {
    border-radius: 4px;
    margin-top: 20px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-of-type {
      margin-top: 30px;
    }

    .package-button {
      &:not(.btn--success) {
        background: var(--lightgrey400-darkgrey4);
        color: var(--darkgrey-white);

        &:hover {
          color: $darkGrey;
        }
      }
    }
  }

  .package-number {
    display: flex;
    align-items: center;

    .package-title {
      margin: 0;
      padding: 0;
      margin-left: 12px;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      color: var(--darkgrey-white);
    }

    .radio-checker {
      cursor: pointer;
      display: none;
    }

    label:before {
      content: ' ';
      display: inline-block;
      position: relative;
      top: 5px;
      left: -12px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 2px solid var(--lightgrey400-darkgrey4);
      background-color: var(--lightgrey400-darkgrey4);
    }

    .radio-checker:checked + label:before {
      content: ' ';
      display: inline-block;
      position: relative;
      border: 4px solid var(--lightgrey400-darkgrey4);
      border-radius: 50%;
      width: 11px;
      height: 11px;
      top: 5px;
      left: -12px;
      background-color: $green700;
    }
  }

  .btn--hidden {
    display: none;
  }

  .credit-braintree-title {
    text-align: center;
    padding: 5px;
    margin-bottom: 20px;

    button {
      color: var(--darkgrey-white);
      margin-right: 10px;
      font-size: 1.25em;

      .btn__circle {
        display: none;
      }
    }

    .credit-title {
      display: inline-block;
    }
  }

  .purchase-success {
    .dialog-modal__closebtn {
      color: var(--black-white) !important;
    }

    .dialog-modal__content {
      width: 420px;
      height: 350px;
      border: 0 !important;
      background: var(--white-darkgrey900);

      .purchase-success--content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;

        img {
          width: 40px;
        }

        .purchase-success-title {
          color: var(--darkgrey-white);
          font-size: 1.375rem;
          font-weight: 500;
          margin: 20px 0 25px;
        }

        .purchase-success-subtitle {
          font-size: 0.8125rem;
          font-weight: 400;
          line-height: 1.3;
          margin: 0;
          color: var(--grey6-lightgrey5);
        }

        .close-window__button {
          margin-top: 40px;
          font-size: 0.875rem;
          width: 180px;
          height: 42px;
        }
      }
    }
  }

  // --------------------------
  // braintree styling section
  // --------------------------

  .braintree-form {
    margin-top: -10px;

    &.is-loaded {
      margin-top: 0;
    }

    * {
      font-family: $baseFont;
    }

    .braintree-options-list {
      padding-bottom: 18px;
    }

    .btn-purchase-confirm {
      margin-top: 15px;
      font-size: 0.8125rem;
      padding: 14px 20px;
      background: var(--blue700-green700);
      color: $light;

      &:hover {
        background: $light;
        color: var(--blue700-green700);
      }
    }

    .braintree-upper-container:before {
      background-color: transparent;
    }

    .braintree-method {
      background: var(--white-darkgrey4);
      border-color: var(--grey5-darkgrey600);

      &.braintree-method--active {
        border-color: var(--darkgreen-lightgreen2);
      }

      .braintree-method__check {
        background: var(--darkgreen-lightgreen2);
      }
    }

    .braintree-loader__indicator {
      &:before {
        border-top-color: var(--grey6-white);
      }

      .braintree-loader__lock {
        fill: var(--grey6-white);
      }
    }

    .braintree-heading {
      color: var(--black-white);
    }

    .braintree-method__label {
      color: var(--black-white);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
